html {
  display: block;
}

@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:300,400,400i,500,700');

body {
    background: #fff;
    color: #414141;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    overflow-x: hidden;
    -webkit-text-stroke: rgba(255, 255, 255, 0.01) 0.1px;
    -webkit-font-smoothing: antialiased !important;
}

a {
    text-decoration: none;
    -ms-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

a:hover,
a:focus,
a:active {
    outline: medium none;
    text-decoration: none;
}

*:focus {
    outline: none;
}

img {
    max-width: 100%;
    height: auto;
}

strong,
b {
    font-weight: 700;
}

i,
em {
    font-style: italic;
}

.clear {
    border: 0;
    clear: both;
    height: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-weight: normal;
    margin: 0;
}

input,
textarea {
    -webkit-appearance: none;
    border-radius: 0;
}

input[type=file]{
    width: 100%;
}

html,
body {
    height: 100%;
}

/**** NAV ****/
nav.navbar-default {
    /*background-color: rgba(0, 0, 0, 0.28);*/
    background-color:#222;
    border-color: rgba(0, 0, 0, 0.05);
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

nav.navbar-default.affix {
    background-color:#222;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

nav.navbar-default .navbar-brand {
    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
}

nav.navbar-default .navbar-brand:focus, nav.navbar-default .navbar-brand:hover {
    color: #fff !important;
    background-color: transparent;
}

nav.navbar-default .navbar-nav>li>a{
  color:#fff;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}

nav.navbar-default .navbar-nav>li>a:focus,
nav.navbar-default .navbar-nav>li>a:hover {
    color: #F05F40;
    background-color: transparent;
}

nav.navbar-default .navbar-nav li a.active,
nav.navbar-default .navbar-nav li a.active:focus,
nav.navbar-default .navbar-nav li a.active:hover {
    color: #F05F40;
    background-color: transparent;
}


/**** NAV END ****/
/**** HEADER ****/
header {
    min-height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

header:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 0;
}

header,
header .header-content {
    position: relative;
    width: 100%;
    text-align: center;
}

header .header-content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 50px;
    z-index: 10;
}

header .header-content .header-content-inner {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    text-shadow: 2px 3px 6px rgba(0, 0, 0, 0.41);
}

h1, h2, h3, h4 {
    font-family: 'Roboto Slab', serif;
}

.header-content-inner h1 {
    font-size: 48px;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.05em;
}

.header-content-inner h1:after {
    content: "";
    display: block;
    width: 80px;
    height: 3px;
    margin: 20px auto 0;
    background: #F05F40;
}

.header-content-inner p {
    font-size: 22px;
    line-height: 1.8;
    color: #e4e4e4;
}
/**** HEADER END ****/
/**** ABOUT ****/
.bg-color{
    background:#F05F40;
}

section{
    /*padding: 80px 0;*/
    padding-top: 80px;
    padding-bottom: 10px;
    position: relative;
    text-align: center;
}

.about-section h3{
    font-size: 36px;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.05em;
}

.about-section h3:after {
    content: "";
    display: block;
    width: 80px;
    height: 3px;
    margin: 20px auto 0;
    background:#c34d34;
}

.about-section p{
    font-size: 18px;
    line-height: 1.8;
    color: #fff;
    margin-bottom: 15px;
}

.btn-default-new{
    background: #c34d34;
    border-radius: 30px;
    border: solid 3px #c34d34;
    color: #ffffff;
    font-size: 18px;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    padding: 0 25px;
    line-height: 3.1;
    margin-top: 30px;
    letter-spacing: 0.05em;
    -ms-transition: all 0.6s ease-in-out;
    -webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
}

.btn-default-new:hover, .btn-default-new:focus{
    background: #fff;
    color: #c34d34;
    border-color: #c34d34;
}
/**** ABOUT END ****/
/**** SERVICE ****/

.service-section h3{
    font-size: 36px;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: #222;
    letter-spacing: 0.05em;
}

.service-section h3:after {
    content: "";
    display: block;
    width: 80px;
    height: 3px;
    margin: 20px auto 0;
    background:#F05F40;
}

.service-items .icon{
    color: #F05F40;
    font-size: 36px;
    display: block;
    width: 130px;
    height: 130px;
    text-align: center;
    border-radius: 100%;
    border: solid 2px;
    border-color:  #F05F40;
    margin: 0 auto 30px;
    line-height: 128px;
}

.service-items .icon-profit{
    color: chocolate;
    font-size: 30px; /*36px*/
    display: block;
    width: 80%;
    /*width: 150px;*/
    height: 90px;
    text-align: center;
    border-radius: 5%;
    border: solid 2px;
    border-color:  chocolate;
    /*margin: 0 auto 30px;*/
    margin: 0 auto 10px;
    /*line-height: 85px;*/

}

.service-items h4{
    font-size: 22px;
    margin-bottom: 20px;
    text-transform: capitalize;
    color: #F05F40;

}

.service-items h4 a{
    color: #222;
}

.service-items h4 a:hover{
    color: #F05F40;
}

.service-items p{
    font-size: 16px;
    line-height: 1.6;
    color: #414141;
    margin-bottom: 15px;
}

/**** SERVICE END ****/
/**** PORTFOLIO ****/

.portfolio-section{
    padding: 0;
}

.portfolio-section .portfolio-section-inner{
    display: table;
    table-layout: fixed;
    width: 100%;
    max-width: 2000px;
}

.portfolio-section-inner .portfolio-section-row{
    display: table-row;
    width: 100%;
}

.portfolio-section-inner .portfolio-section-data{
    display: table-cell;
    width: 33.33%;
    vertical-align: middle;
    height: 420px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}

.portfolio-section-inner .portfolio-section-data .portfolio-section-text{
    position: absolute;
    top: 150%;;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
    margin: auto;
     -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.portfolio-section-inner .portfolio-section-data:hover .portfolio-section-text{
    top: 0;
}

.portfolio-section-text .portfolio-section-text-inner{
    position: absolute;
    top: 50%;
    right: 20px;
    left: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.portfolio-section-text p{
    font-size: 28px;
    line-height: 1.6;
    color: #fff;
}

.portfolio-section-text a{
    color: #F05F40;
    font-size: 14px;
}

.portfolio-section-text a:hover{
    color: #fff;
}
/**** PORTFOLIO ****/
/**** FOOTER ****/

footer{
    background: #222;
    padding: 20px 0;
    position: relative;
    text-align: center;
}

footer h3{
    font-size: 24px;
    margin-bottom: 30px;
    text-transform: capitalize;
    color: #fff;
    letter-spacing: 0.05em;
}

footer h3:after {
    content: "";
    display: block;
    width: 80px;
    height: 3px;
    margin: 20px auto 0;
    background:#F05F40;
}

footer p{
    font-size: 18px;
    line-height: 1.6;
    color: #adadad;
}

.gap{
    display: block;
    margin-top: 50px;
}

footer span.glyphicon{
    color: #adadad;
    font-size: 28px;
    display: block;
    margin: 0 auto 30px;
}

footer a{
    color: #adadad;

}

footer a:hover{
    color: #F05F40;
}

.disclaim{
  color: wheat;
}

hr {
  border-top: 1px solid #8c8b8b;
  border-bottom: 1px solid #fff;
}

.row {
  margin-top: 5px;
}

.row label{
  margin-top: 5px;
}

.service-items h4 a {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: rgb(167, 22, 22);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: rgb(167, 22, 22);
  cursor: pointer;
}


/** SPINNER CREATION **/

.loader {
  position: relative;
  text-align: center;
  margin: 15px auto 35px auto;
  z-index: 9999;
  display: block;
  width: 80px;
  height: 80px;
  border: 10px solid rgba(0, 0, 0, .3);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}


/** MODAL STYLING **/

.modal-dialog {
    top: 30%;
    margin: 0 auto;
}

.modal-content {
  border-radius: 0px;
  box-shadow: 0 0 20px 8px rgba(0, 0, 0, 0.7);
}

.modal-backdrop.show {
  opacity: 0.75;
}

.loader-msg {
  p {
    font-size: 13px;
    color: #666;
    small {
      font-size: 11.5px;
      color: #999;
    }
  }
}

canvas{
    width: 100%;
}
